import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import "./styles.css";
import one from '../img/1.jpg';
import two from '../img/2.jpg';
import three from '../img/3.jpg';
import five from '../img/5.jpg';
import six from '../img/6.jpg';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};
 
const Slider = () => {
  return (
    <section className="section section-bg">
				<div className="container">
					<div className="sec-title text-center">
						<h2>Actual Site Pictures of Plots in Jewar</h2>
					</div>
					<div className="row">
                    <div className="col-lg-12">
					
     
         
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
         
           
            <div className="slider" key="">
              <img src={one} alt="movie" />
            </div>
            <div className="slider" key="">
              <img src={two} alt="movie" />
            </div>
            <div className="slider" key="">
              <img src={three} alt="movie" />
            </div>
            <div className="slider" key="">
              <img src={five} alt="movie" />
            </div>
            <div className="slider" key="">
              <img src={six} alt="movie" />
            </div>
          
        
      </Carousel>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
         
           
            <div className="slider" key="">
              <img src={one} alt="movie" />
            </div>
            <div className="slider" key="">
              <img src={two} alt="movie" />
            </div>
            <div className="slider" key="">
              <img src={three} alt="movie" />
            </div>
            <div className="slider" key="">
              <img src={five} alt="movie" />
            </div>
            <div className="slider" key="">
              <img src={six} alt="movie" />
            </div>
          
        
      </Carousel>
      </div>
      </div>
				</div>
			</section>
  );
};
export default Slider