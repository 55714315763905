import React from 'react'

function Footer() {
  return (
    <div>
      <footer id="footer" className="footer-texts-more-lighten">
			<div className="container pt-lg-0 pt-2">
				<div className="py-lg-2 pb-3">
					<div className="row">
						<div className="col-12 text-center">
							<p className="mb-0 text-white line-height-3"> Copyrights © <span className="text-white" id="currentYear"></span>
								All Right Reserved</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
    </div>
  )
}

export default Footer
