import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

import Header from './components/Header'; 
import Slider from './components/Slider';
import Footer from './components/Footer';

import banner from './img/banner.jpg'; 
import location from './img/location.jpg';
import price from './img/price.jpg';
import map from './img/map.jpg';
import plot1 from './img/plot1.jpg';
import plot2 from './img/plot2.jpg';
import residency1 from './img/residency1.jpg';
import residency2 from './img/residency2.jpg';
import residency3 from './img/residency3.jpg';
import residency4 from './img/residency4.jpg';
import payment from './img/payment.png';
 

function App() {
  return (
    <div className="App">
      <Header/>
      <section className="position-relative" id="bannerSection">
				<img src={banner} alt="" className="img-fluid d-lg-block d-none"/>
				<img src={banner} alt="" className="img-fluid d-lg-none d-block d-md-block"/>
				<div className="container formSection">
					<div className="row">
						<div className="col-lg-8 mb-3">
							<div className="h-100"></div>
						</div>
						<div className="col-lg-4 mb-4 mb-md-5 mb-lg-5">
							<div className="banner-form">
								<div className="mb-3">
									<h2>Only 51 Plots Available. Hurry Now! </h2>
									<div className="counter-class" data-date="2024-05-03 23:59:59">
                                        
										<div><span className="counter-days">00</span> Days</div>
										<div><span className="counter-hours">00</span> Hours</div>
										<div><span className="counter-minutes">00</span> Minutes</div>
										<div><span className="counter-seconds">00</span> Seconds</div>
									</div>
								</div>



																<h2 className="heading-bg">Form</h2>
								<form action="mailer.php" method="post" className="form-squared-borders contact-form">
									<input type="hidden" name="utm_source" value=""/>
									<input type="hidden" name="utm_campaign" value=""/>
									<div className="row">
										<div className="form-group col-lg-12">
											<input type="text" value="" placeholder="Enter your name*" data-msg-required="Please enter your name." className="form-control text-3" name="name" required=""/>
										</div>
										<div className="form-group col-lg-12">
											<input type="email" value="" placeholder="Enter Email Id" data-msg-required="Please enter your email address." data-msg-email="Please enter a valid email address." className="form-control text-3" name="email" required=""/>
										</div>
										<div className="form-group col-lg-12">
											<input type="text" value="" placeholder="Enter 10 Digit Mobile Number*" data-msg-required="Please enter your mobile no." data-msg-email="Please enter a valid  mobile no." className="form-control text-3" name="mobile" pattern="[0-9]{10}" title="Should be 10 digit number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" minlength="10" maxlength="10" required=""/>
										</div>
										<div className="form-group col-lg-12">
											<textarea maxlength="3000" placeholder="Message" data-msg-required="Please enter your message." rows="3" className="form-control text-3 h-auto py-2" name="message" required=""></textarea>
										</div>
										<div className="form-group col-lg-12">
											<input type="submit" name="Submit" className="w-100 btn rounded-0 btn-primary"/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
            <section className="section" id="section3">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-10 col-lg-10">
							<p className="text-lg-4 mb-2 text-center text-black">This Navratri experience housing comfort, affordability and connectivity in the midst of one f the prime property
								locations in India: The Yamuna Expressway. The Yamuna Expressway is
								one of the most desirable and highly sought-after property locations in India due to
								its proximity to important landmarks surrounding it. </p>
						</div>
					</div>
				</div>
			</section>
            <section className="section image-bg why-section" id="section4">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="card">
								<div className="card-body">
									<h3> Why invest in the <br/>
										Yamuna Expressway Plots Scheme? </h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
            <section className="section show-section">
				<div className="payment-icon"> <a href=" https://pages.razorpay.com/pl_Nht3k7g5OYiuxV/view" target="_blank"> 
                <img src={payment} alt="" className="img-fluid"/> </a> </div>
				<div className="container">
					<div className="sec-title text-center">
						<h2>High-Profit Margin</h2>
					</div>
					<div className="row justify-content-between">
						<div className="col-xl-3 col-lg-4 mb-lg-0 mb-4">
							<div className="usp-card">
								<div className="card-icon text-center mb-4"> <img src="img/icon1.png" alt="" className="img-fluid"/> </div>
								<div className="usp-card-content">
									<div className="strip-heading">
										<h3>Infrustructure</h3>
									</div>
									<ul>
										<li>Wide Roads</li>
										<li>Well-planned towns</li>
										<li>Residential and Commercial Complexes</li>
										<li>Schools and World Class Universities</li>
										<li>Hospitals and Entertainment</li>
										<li>Metro connectivity</li>
										<li>Night Safari</li>
										<li>Railway with Cargo facility connecting via DMIC</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-4 mb-lg-0 mb-4">
							<div className="usp-card">
								<div className="card-icon text-center mb-4"> <img src="img/icon2.png" alt="" className="img-fluid"/> </div>
								<div className="usp-card-content">
									<div className="strip-heading">
										<h3>Strategic Location </h3>
									</div>
									<ul>
										<li>Near Noida International Airport (Jewar Airport)</li>
										<li>15 minutes distance from Noida Film City</li>
										<li>Proximity to major cities like Delhi, Ghaziabad and Noida</li>
										<li>Yamuna Expressway connects Greater Noida to Agra</li>
										<li>Connectivity with the Delhi-Mumbai Expressway</li>
										<li>F1 Circuit Proximity</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-4 mb-lg-0 mb-4">
							<div className="usp-card">
								<div className="card-icon text-center mb-4"> <img src="img/icon3.png" alt="" className="img-fluid"/> </div>
								<div className="usp-card-content">
									<div className="strip-heading">
										<h3>Industrial Development </h3>
									</div>
									<ul>
										<li>Competitive land prices</li>
										<li>IT Parks</li>
										<li>Establishment of factories</li>
										<li>Commercial Complexes</li>
										<li>Special Economic Zone</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
          
      <Slider/>
      <section className="section about-section">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 text-center mb-lg-0 mb-3">
							<div className="card"> <a href="img/location.jpg" className="lightbox" data-plugin-options="{'type':'image'}">
									<img className="card-img-top" src={location} alt="Card Image" /> </a>
								<div className="card-body">
									<h4 className="card-title mb-1 text-5">Location</h4>
								</div>
							</div>
						</div>
						<div className="col-lg-4 text-center mb-lg-0 mb-3">
							<div className="card"> <a href="img/price.jpg" className="lightbox" data-plugin-options="{'type':'image'}"> <img
										className="card-img-top" src={price} alt="Card Image" /> </a>
								<div className="card-body">
									<h4 className="card-title mb-1 text-5">Price List</h4>
								</div>
							</div>
						</div>
						<div className="col-lg-4 text-center">
							<div className="card"> <a href="img/map.jpg" className="lightbox" data-plugin-options="{'type':'image'}"> <img
										className="card-img-top" src={map} alt="Card Image" /> </a>
								<div className="card-body">
									<h4 className="card-title mb-1 text-5">Map</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
      <section className="section secondary-bg">
				<div className="container">
					<div className="sec-title text-center">
						<h2>Jewar Airport Plots Size</h2>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-8 mb-3"> <img src={plot1} alt="" className="img-fluid rounded-2"/> </div>
						<div className="col-lg-8"> <img src={plot2} alt="" className="img-fluid rounded-2"/> </div>
					</div>
				</div>
			</section>
      <section className="section section-bg">
				<div className="container">
					<div className="sec-title text-center">
						<h2>Delivered Projects</h2>
					</div>
					<div className="row">
						<div className="col-lg-6 col-md-6 mb-3"> <a className="img-thumbnail d-block lightbox" href="img/residency1.jpg"
								data-plugin-options="{'type':'image'}"> 
								<img src={residency1} alt=""
									className="img-fluid rounded-2"/> </a> </div>
						<div className="col-lg-6 col-md-6 mb-3"> <a className="img-thumbnail d-block lightbox" href="img/residency2.jpg"
								data-plugin-options="{'type':'image'}"> 
								<img src={residency2} alt=""
									className="img-fluid rounded-2"/> </a> </div>
						<div className="col-lg-6 col-md-6 mb-3"> <a className="img-thumbnail d-block lightbox" href="img/residency3.jpg"
								data-plugin-options="{'type':'image'}"> 
								<img src={residency3} alt=""
									className="img-fluid rounded-2"/> </a> </div>
						<div className="col-lg-6 col-md-6 mb-3"> <a className="img-thumbnail d-block lightbox" href="img/residency4.jpg"
								data-plugin-options="{'type':'image'}"> 
								<img src={residency4} alt=""
									className="img-fluid rounded-2"/> </a> </div>
					</div>
				</div>
			</section>
      <section className="py-4">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<iframe width="100%" height="500" src="https://www.youtube.com/embed/6hWCktlj0Kk"
								title="Harit Homes Residence Plot on Yamuna Expressway Near International Airport" frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
						</div>
					</div>
				</div>
			</section>
			<div className="home-intro bg-primary py-3 mb-0" id="home-intro">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-12 my-lg-auto mb-3 text-center">
							<h2>Residential Plots near Jewar International Airport <br/>
								on Yamuna Expressway.</h2>
						</div>
					</div>
				</div>
			</div>
			<div className="py-3">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-6">
							<p className="text-lg-4 text-3 mb-3 text-center text-black">Buy land near Jewar Airport and the upcoming Film City on Yamuna Expressway surrounded by natural beauty. Having a beautiful property in Jewar is not only a financially savvy decision but one that will lead to true contentment. </p>
							<p className="text-lg-4 text-3 mb-3 text-center text-black">Book a site visit today to authority plots in Jewar by Harit Vatika, a very well connected place to Delhi NCR, opposite F1 Track.</p>
						</div>
					</div>
				</div>
			</div>
      <a href="https://wa.me/919205704784" class="whatsapp-float text-decoration-none" target="_blank"> <i
			class="fab fa-whatsapp whatapp-btn"></i> </a>
	<div class="sticky-bar"> <a href="tel:+91 92057 04784" class="call-cta"><i
				class="fas fa-phone-alt me-2 phone-icon"></i>Contact Us</a> </div>
      <Footer/>
      
    </div>
  );
}

export default App;
