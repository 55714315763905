import React from 'react';
import top1 from '../img/top1.jpg';
import top2 from '../img/top2.jpg';
import top3 from '../img/top3.jpg';
import logo from '../img/logo.jpg';

function Header() {
  return (
    <div>
      <section className="header-logo" id="section1">
				<div className="container">
					<div className="row justify-content-between align-items-center">
						<div className="left-icon-box col-lg-4 col-md-4"> 
							<img src={top1} alt="" className="img-fluid"/> 
							<img src={top2} alt="" className="img-fluid"/> 
							<img src={top3} alt="" className="img-fluid"/> 
						</div>
						<div className="col-lg-3 col-md-4 right-icon-box text-end"> 
							<img src={logo} alt="" className="img-fluid"/> 
						</div>
						<div className="col-lg-3 col-md-4">
							<div className="button-group"> 
								<a href="tel:+91 92057 04784" className="text-decoration-none cta-button"> 
									<span>Contact Us</span> <span className="button-icon"> <i className="fas fa-phone text-white text-4"></i> </span> </a> 
							</div>
						</div>
					</div>
				</div>
			</section>
    </div>
  )
}

export default Header
